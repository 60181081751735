<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.625 6.5H1.875C1.52497 6.5 1.34995 6.5 1.21626 6.56812C1.09865 6.62804 1.00304 6.72365 0.943121 6.84126C0.875 6.97495 0.875 7.14997 0.875 7.5V11.125C0.875 11.475 0.875 11.65 0.943121 11.7837C1.00304 11.9013 1.09865 11.997 1.21626 12.0569C1.34995 12.125 1.52497 12.125 1.875 12.125H4.625M4.625 12.125H8.375M4.625 12.125L4.625 4.375C4.625 4.02497 4.625 3.84995 4.69312 3.71626C4.75304 3.59865 4.84865 3.50304 4.96626 3.44312C5.09995 3.375 5.27497 3.375 5.625 3.375H7.375C7.72503 3.375 7.90005 3.375 8.03374 3.44312C8.15135 3.50304 8.24696 3.59865 8.30688 3.71626C8.375 3.84995 8.375 4.02497 8.375 4.375V12.125M8.375 12.125H11.125C11.475 12.125 11.65 12.125 11.7837 12.0569C11.9013 11.997 11.997 11.9013 12.0569 11.7837C12.125 11.65 12.125 11.475 12.125 11.125V1.875C12.125 1.52497 12.125 1.34995 12.0569 1.21626C11.997 1.09865 11.9013 1.00304 11.7837 0.943121C11.65 0.875 11.475 0.875 11.125 0.875H9.375C9.02497 0.875 8.84995 0.875 8.71626 0.943121C8.59865 1.00304 8.50304 1.09865 8.44312 1.21626C8.375 1.34995 8.375 1.52497 8.375 1.875V4"
      stroke="white"
      stroke-opacity="0.4"
      stroke-width="1.255"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
